@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Inter:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your CSS file */
.carousel-container {
  /* Add styling for your carousel container */
}

.testimonial-item {
  /* Styling for each testimonial item */
}
  
@import './custom-scrollbar.css';
